.upcoming-events-container{
    width: 40rem;
    background: white;
    box-shadow: -6px 6px 40px rgba(0, 0, 0, 0.08);

    .heading{
        font-family: roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 41px;
        letter-spacing: 0.08em;
        text-align: center;
        margin: 4rem 0;
    }

    .upcoming-events{
        width: 75%;
        margin: auto;
        margin-bottom: 4rem;
        position: relative;
        box-shadow: 3px 12px 34px rgba(0, 0, 0, 0.1);

        .upcoming-event{
            padding: 2.3rem 2.7rem;

            &:hover{
                cursor: default;
            }

            h1{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 127.2%;
                letter-spacing: -0.035em;
            }

            h3{
                color: black;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 127.2%;
                letter-spacing: -0.035em;
            }

            &:not(:last-child){
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            }
        }

        .arrow-btn{
            width: 4rem;
            height: 4rem;
            position: absolute;
            text-decoration: none;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: -0.035em;
            color: gray;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            border-radius: 50%;
            background: white;
            z-index: 999;
            text-align: center;
            transition: all 0.5;

            &:hover{
                background-color: gray;
                color: white;
                transform: translate(50%, -50%) scale(1.05);
            }
        }
    }
}
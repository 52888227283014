.work {
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 4.9rem;
  line-height: 7.3rem;
  letter-spacing: 0.1em;
  margin: 2rem 0;
  min-width: 180px;

  p {
    margin-top: -2rem;
  }
}

@media only screen and (max-width: 950px) {
  .work {
    width: 50%;
  }
}

@media only screen and (max-width: 470px) {
  .work {
    font-size: 20px;
    line-height: 40px;
    margin: 0;
  }
}

.project-card{
  perspective: 1500px;
  background: transparent;
  -webkit-tap-highlight-color: transparent;
  width: 32%;
  margin-bottom: 2rem;
  
    .card{
        transition: transform 1s;
        transform-style: preserve-3d;
        min-height: 100%;
        box-shadow: -6px 8px 22px rgba(0, 0, 0, 0.1);
      
    }
    &:hover {
      cursor: pointer;

        .card {
            transform: rotateY(-180deg);
            // .flip-card-front{
            //     display: none;
            // }
          }

        
    }
    
      .flip-card-front, .flip-card-back {
        // position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      .flip-card-back {
        position: absolute;
        top: 0;
        background-color: white;
        color: black;
        transform: rotateY(180deg);
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn{
          font-size: 14px;
        }
      }

    .project-details{
      margin: 1rem 0;
    }

    .project-img{
        width: calc(100%);
        object-fit: cover;
        max-height: 220px;
        // height: calc(10%);
        // margin: 1rem;
    }

    .project-description{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 127.2%;
        text-align: justify;
        letter-spacing: -0.035em;
        margin: 0 1.5rem;
        padding-bottom: 1rem;
    }

    .project-name{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 127.2%;
        // text-align: justify;
        letter-spacing: -0.035em;
        margin: 0 1.5rem;
        padding-bottom: 1rem;        
    }

    .project-intro{
      font-family: Poppins;
      font-size: 1.7rem;
      font-weight: normal;
      margin: 0 1.5rem;
      padding-bottom: 1rem;
    }

    .project-date{
      font-family: Poppins;
      font-size: 1.5rem;
      font-weight: normal;
      margin: 0 1.5rem;
      padding-bottom: 1rem;
      color: rgb(114, 114, 114);
    }
}

@media only screen and (max-width: 600px){
  .project-card{
    .project-img{
      max-height: 130px;
    }
    .project-description{
      font-size: 14px;
      line-height: 110%;
    }
  }
}

@media only screen and (max-width: 860px){
  .project-card{
    width: 48%;

    .project-img{
      max-height: 170px;
    }
  }
}

@media only screen and (max-width: 470px){
  .project-card{
    width: 100%;
  }
}
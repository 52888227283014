.landing-section {
  width: 100%;
  position: relative;
  height: 100vh;
  background-image: linear-gradient(
      90.05deg,
      rgba(23, 23, 23, 0.99) 28.76%,
      rgba(0, 0, 0, 0.4)
    ),
    url(../../Assets/LandingImg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;

  .heading {
    color: #fff;
    width: 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    margin: 0 5%;

    .heading-primary {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 6rem;
      line-height: 7rem;
      letter-spacing: 0.12em;
      margin-bottom: 2rem;
    }

    .heading-secondary {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
      letter-spacing: 0.12em;
      margin-bottom: 2.5rem;
    }

    .heading-btn {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 127.2%;
      letter-spacing: -0.035em;
      color: white;
      border: 2px solid white;
      outline: none;
      background: transparent;
      padding: 1rem 2rem;
      transition: all 0.5s;
      cursor: pointer;

      .icon {
        margin-left: 1rem;
      }

      &:hover {
        color: #161616;
        background-color: white;
        font-weight: bold;
      }
    }
  }

  .scroll-icon {
    font-size: 7rem;
    position: absolute;
    bottom: 5%;
    animation: change-color 4s linear infinite;
    cursor: pointer;

    &:hover {
      animation-play-state: paused;
    }
  }

  .scroll-icon {
    color: white;
    font-size: 3rem;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes stroke-offset {
  100% {
    stroke-dashoffset: -35%;
  }
}

@keyframes change-color {
  0% {
    color: #c2c2c2;
    transform: translateY(0px);
  }
  20% {
    color: #949494;
    transform: translateY(-4px);
  }
  40% {
    color: #676767;
    transform: translateY(-8px);
  }
  60% {
    color: #7b7b7b;
    transform: translateY(-8px);
  }
  80% {
    color: #bfbfbf;
    transform: translateY(-4px);
  }
  100% {
    color: #cecece;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 1200px) {
  .landing-section {
    .heading {
      .heading-primary {
        font-size: 5rem;
        line-height: 6rem;
        margin-bottom: 1rem;
      }
      .heading-secondary {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .landing-section {
    .heading {
      .heading-primary {
        font-size: 4.5rem;
        line-height: 5.5rem;
      }
      .heading-secondary {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .landing-section {
    background-color: #161616;
    background-image: linear-gradient(
        181.51deg,
        #161616 2.5%,
        rgba(0, 0, 0, 0.58) 98.72%
      ),
      url(../../Assets/LandingImg.webp);
    background-position: 50% bottom;
    max-height: 100vh;

    .heading {
      top: 55%;
      transform: translateY(-50%);
      .heading-primary {
        font-size: 6rem;
        line-height: 7.5rem;
        text-align: left;
      }
      .heading-secondary {
        font-size: 2.4rem;
        line-height: 3.2rem;
        text-align: left;
      }
      .heading-btn {
        font-size: 1.4rem;
        border: 1px solid white;
        padding: 1rem 1.7rem;
        transition: all 0.5s;
        margin: 50px auto;
      }
    }
  }
}

.domain-section{
  background: #161616;
  margin-top: 7rem;
  width: 100%;
  padding-top: 3rem;
  overflow: hidden;

  .domain-container{
    white-space: nowrap;
    margin-top: 3rem;
    padding-bottom: 3rem;
    display: flex;

    .marquee-item{
      animation: marquee 15s linear infinite;

      &:hover{
        animation-play-state: paused;
      }
    }
  }
}
@keyframes marquee{
  0%{transform: translate(0, 0);}
  50%{transform: translate(-28%, 0);}
  100%{transform: translate(0, 0);}
}

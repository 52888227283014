.contact-us-section{
    background: #161616;
    padding-top: 5rem;

    .links{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 2.3rem;
        line-height: 3.4rem;
        margin-left: 10%;
        padding-top: 4rem;

        a{
            color: white;
            text-decoration: none;

            &:not(:last-child){
                margin-right: 6.5rem;
            }

            &:hover{
                color: gray;
            }
        }
    }

    .content{
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 1rem;

        .heading{
            h1{
                font-family: roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 6.5rem;
                line-height: 9.5rem;
                letter-spacing: 0.12em;
            }

            h3{
                font-family: roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 2.4rem;
                line-height: 3.6rem;
                letter-spacing: 0.12em;
            }
        }

        .message-form{
            width: 32%;
            box-shadow: -8px 8px 17px rgba(0, 0, 0, 0.28);
            border-radius: 5px;
            padding: 2.7rem 3.1rem;

            input, textarea{
                color: white;
                width: calc(100% - 2 * (2.7rem));
                margin-bottom: 3rem;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 1.7rem;
                line-height: 2.5rem;
                padding: 1.8rem 2.7rem;
                background: #272727;
                border: none;
                outline: none;
                resize: none;

                &::-webkit-scrollbar{
                    width: 7.5px;
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb{
                    background-color: #4e4e4e;
                    border-radius: 10px;
                }
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus, 
            input:-webkit-autofill:active  {
                -webkit-box-shadow: 0 0 0 30px #272727 inset !important;
            }

            input:-webkit-autofill {
                -webkit-text-fill-color: white !important;
            }

            button{
                outline: none;
                border: 2px solid white;
                color: white;
                background: transparent;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 2.3rem;
                line-height: 3.4rem;
                letter-spacing: 0.12em;
                padding: 0.2rem 1.6rem;
                margin-left: 50%;
                transform: translateX(-50%);
                transition: all 0.5s;

                &:hover{
                    cursor: pointer;
                    background: white;
                    color: #161616;
                }
            }
        }
    }
}

@media only screen and (max-width: 950px){
    .contact-us-section{
        .content{
            .heading{
                h1{
                    font-size: 5rem;
                    line-height: 6rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px){
    .contact-us-section{
        .content{
            .heading{
                width: 80%;
                margin-top: 7rem;
                margin-bottom: 3rem;
            }
            .message-form{
                width: 80%;
            }
        }
    }
}

@media only screen and (max-width: 470px){
    .contact-us-section{
        .links{
            font-size: 14px;
            line-height: 18px;
        }
        .content{
            .heading{
                h1, h3{
                    font-size: 17px;
                    line-height: 22px;
                }
            }
            .message-form{
                button{
                    font-size: 15px;
                    line-height: 25px;
                }
            }
        }
    }
}
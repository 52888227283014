.board-section{
    margin-bottom: 7rem;
    
    .section-heading{
        margin: 7rem 0 3rem 0;
    }

    .board-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        .circle{
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media only screen and (max-width: 950px){
    .board-section{
        .section-heading{
            h1{
                font-size: 4.2rem !important;
            }
        }
        
        .board-list{
            .circle{
                display: none;
                visibility: hidden;
            }
        }
    }
}

@media only screen and (max-width: 470px){
    .board-section{
        .section-heading{
            h1{
                font-size: 17px !important;
                line-height: 25px;
            }
        }
    }
}
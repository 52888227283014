.events-section {
  position: relative;

  .section-heading {
    margin: 7rem 0 3rem 0;
  }

  .events {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-container {
      width: 80%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: row;
      grid-row-gap: 1rem;
      grid-column-gap: 1rem;
      align-items: stretch;
    }

    .upcoming-events-container {
      display: none;
      visibility: hidden;
    }
  }

  .buttons {
    width: 70%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-around;
  }

  .btn {
    width: fit-content;
    border: none;
    outline: none;
    background: #161616;
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 127.2%;
    padding: 5px 10px;
    border: 2px solid #161616;
    transition: all 0.5s;

    &:hover {
      background: white;
      color: #161616;
      cursor: pointer;
    }
  }

  .circle-1 {
    display: none;
    visibility: hidden;
    position: absolute;
    bottom: 20rem;
    right: 20rem;
    z-index: -1;
  }
}

@media only screen and (min-width: 1350px) {
  .events-section {
    .events {
      justify-content: space-evenly;

      &-container {
        width: 65%;
        margin: 0;
      }

      .upcoming-events-container {
        display: block;
        visibility: visible;
        position: relative;
      }
    }
    .upcomming-sessions-btn {
      display: none;
      visibility: hidden;
    }
    .circle-1 {
      display: block;
      visibility: visible;
    }
  }
}

@media only screen and (max-width: 950px) {
  .events-section {
    .events {
      &-container {
        width: 95%;
      }
    }
    .buttons {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .events-section {
    .events {
      &-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  .events-section {
    .section-heading {
      margin: 5rem 0 2rem 0;
    }

    .events {
      &-container {
        width: 90%;
        padding-bottom: 3rem;
        overflow-x: scroll;
        scroll-behavior: smooth;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, 300px);
        grid-auto-columns: 300px;
      }
    }

    .btn {
      font-size: 12px;
      line-height: 127.2%;
    }
  }
}

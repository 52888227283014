.upcoming-event-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5rem;
  padding: 5rem 0;

  .img-container {
    width: 30%;

    .poster {
      width: 100%;
    }
  }

  .registration-form-container {
    width: 40%;

    .registration-form {
      width: 100%;
      height: 440px;
      border-radius: 10px;
    }
  }

  .event-details {
    width: 40%;
    font-size: 2rem;
    white-space: pre-line;
    text-align: justify;

    a {
      color: gray;
      transition: all 0.5s;

      &:hover {
        color: black;
        transform: scale(1.05);
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .upcoming-event-section {
    flex-direction: column;

    .img-container {
      width: 30%;
      margin-bottom: 3rem;
    }
  }
}

@media only screen and (max-width: 950px) {
  .upcoming-event-section {
    flex-direction: column;

    .img-container {
      margin-bottom: 5rem;
      width: 50%;
    }

    .registration-form-container,
    .event-details {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 470px) {
  .upcoming-event-section {
    overflow: hidden;

    .img-container {
      margin-bottom: 2rem;
      width: 60%;
    }

    .registration-form-container {
      width: 150%;
      margin: -20% 0;

      .registration-form {
        padding: 1rem;
        transform: scale(0.7);
      }
    }
  }
}

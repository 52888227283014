.about-us-section{
    position: relative;
    overflow-x: hidden;

    .section-heading{
        margin: 7rem 0 3rem 0;
    }

    .about-us{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5rem;

        .video-container{
            width: 37%;
            margin-right: 1rem;
    
            .video{
                width: 100%;
                object-fit: cover;
            }
        }
    
        .about-us-text{
            width: calc(38% - 6rem);
            padding: 4rem 6rem;
            box-shadow: -9px 10px 31px rgba(0, 0, 0, 0.07);

            h1{
                font-family: roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 4.8rem;
                line-height: 7.1rem;
                text-align: center;
                letter-spacing: 0.08em;
            }

            p{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 1.8rem;
                line-height: 2.3rem;
                letter-spacing: -0.03em;
                margin-top: 3rem;
                text-align: justify;
            }
        }
    }

    .our-vision{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5rem;

        .image{
            width: 37%;
            margin-left: 1rem;
        }

        .our-vision-text{
            width: calc(38% - 6rem);
            padding: 4rem 6rem;
            box-shadow: -9px 10px 31px rgba(0, 0, 0, 0.07);

            h1{
                font-family: roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 4.8rem;
                line-height: 7.1rem;
                text-align: center;
                letter-spacing: 0.08em;
            }

            p{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 1.8rem;
                line-height: 2.3rem;
                letter-spacing: -0.03em;
                margin-top: 3rem;
                text-align: justify;
            }
        }
    }

    .our-work{
        background: #161616;
        width: 100%;
        display: flex;
        justify-content: space-around;
        color: #797979;
        text-align: center;
    }

    .circle-1{
        position: absolute;
        top: -2rem;
        left: 3rem;
        z-index: -1;
    }

    .circle-2{
        position: absolute;
        bottom: 10rem;
        right: 3rem;
        z-index: -1;
    }

    .line{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        display: none;
        visibility: hidden;

        svg{
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 950px){
    .about-us-section{
        .about-us{
            flex-direction: column-reverse;

            .video-container{
                width: 64%;
            }

            .about-us-text{
                width: 80%;
                margin-bottom: 3rem;
            }
        }

        .our-vision{
            flex-direction: column;

            .image{
                display: none;
                visibility: hidden;
            }

            .our-vision-text{
                width: 80%;
            }
        }

        .our-work{
            flex-wrap: wrap;
            width: 100%;
            padding: 2.5rem 0;
        }

        .circle-1, .circle-2{
            display: none;
            visibility: hidden;
        }
    }
}

@media only screen and (max-width: 470px){
    .about-us-section{
        .section-heading{
            margin: 5rem 0 2rem 0;
        }
        .about-us{
            .about-us-text{
                padding: 2rem 3rem;
                h1{
                    display: none;
                    visibility: hidden;
                }

                p{
                    margin-top: 0;
                    font-size: 14px;
                    line-height: 127.2%;
                }
            }
        }

        .our-vision{
            .our-vision-text{
                padding: 2rem 3rem;
                h1{
                    font-size: 20px;
                    line-height: 25px;
                }

                p{
                    margin-top: 2rem;
                    font-size: 14px;
                    line-height: 127.2%;
                }
            }
        }
    }
}
* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 57%;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 7.5px;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(50, 50, 50);
  border-radius: 10px;
}

@media only screen and (max-width: 950px) {
  html {
    font-size: 57%;
  }
}

@media only screen and (max-width: 470px) {
  html {
    font-size: 50%;
  }
}

.loader {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  transition: all 1s ease-in;

  p {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
    align-items: center;
    text-align: center;
    letter-spacing: 0.12em;
  }

  .arduino {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    border: 4px solid gray;
    border-top: 4px solid #fff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    animation: spin 2s linear infinite;
  }
}

.app {
  transition: all 1s ease-in;
}

@keyframes spin {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

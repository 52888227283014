.projects-section{
    position: relative;

    .section-heading{
        margin: 7rem 0 3rem 0;
    }
    .btn{
        margin: 3rem 0 0 50%;
        width: fit-content;
        transform: translateX(-50%);
        border: none;
        outline: none;
        background: #161616;
        color: white;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 127.2%;
        padding: 5px 10px;
        border: 2px solid #161616;
        transition: all 0.5s;

        &:hover{
            cursor: pointer;
            background: white;
            color: #161616;
        }
    }
    .projects{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 3rem;

        &-container{
            width: 75%;
            padding: 0 5%;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 2%;
            // grid-template-columns: repeat(3, 1fr);
            // grid-auto-flow: row;
            // justify-content: stretch;
            // grid-row-gap: 3rem;
            // grid-column-gap: 1rem;
            background-color: transparent;
        }
    }
}

@media only screen and (max-width: 1100px){
    .projects-section{
        .projects{
            &-container{
                width: 85%;
            }
        }
    }
}

@media only screen and (max-width: 950px){
    .projects-section{
        .projects{
            &-container{
                width: 95%;
            }
        }
    }
}

@media only screen and (max-width: 860px){
    .projects-section{
        .projects{
            &-container{
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@media only screen and (max-width: 470px){
    .projects-section{
        .section-heading{
            margin: 5rem 0 2rem 0;
        }

        .projects{
            &-container{
                width: 80%;
                overflow-x: scroll;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                
            }
        }

        .btn{
            font-size: 12px;
            line-height: 127.2%;
        }
    }
}